import website21 from "./images/website21.jpg";
import website22 from "./images/website22.jpg";
import website23 from "./images/website23.jpg";
import website24 from "./images/website24.jpg";
import website25 from "./images/website25.jpg";
import website26 from "./images/website26.jpg";
import website27 from "./images/website27.jpg";
import website28 from "./images/website28.jpg";
import website29 from "./images/website29.jpg";
import website30 from "./images/website30.jpg";

const WorkImages2 = [
  website21,
  website22,
  website23,
  website24,
  website25,
  website26,
  website27,
  website28,
  website29,
  website30,
];

export default WorkImages2;
