import website11 from "./images/website11.jpg";
import website12 from "./images/website12.jpg";
import website13 from "./images/website13.jpg";
import website14 from "./images/website14.jpg";
import website15 from "./images/website15.jpg";
import website16 from "./images/website16.jpg";
import website17 from "./images/website17.jpg";
import website18 from "./images/website18.jpg";
import website19 from "./images/website19.jpg";
import website20 from "./images/website20.jpg";

const WorkImages1 = [
  website11,
  website12,
  website13,
  website14,
  website15,
  website16,
  website17,
  website18,
  website19,
  website20,
];

export default WorkImages1;
