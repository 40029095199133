import website1 from "./images/website1.jpg";
import website2 from "./images/website2.jpg";
import website3 from "./images/website3.jpg";
import website4 from "./images/website4.jpg";
import website5 from "./images/website5.jpg";
import website6 from "./images/website6.jpg";
import website7 from "./images/website7.jpg";
import website8 from "./images/website8.jpg";
import website9 from "./images/website9.jpg";
import website10 from "./images/website10.jpg";

const WorkImages = [
  website1,
  website2,
  website3,
  website4,
  website5,
  website6,
  website7,
  website8,
  website9,
  website10,
];

export default WorkImages;
